import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  CompositeDecorator,
  ContentState,
  convertFromRaw,
  convertToRaw,
  EditorState,
  getVisibleSelectionRect,
  Modifier,
  RichUtils,
} from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import Editor from "draft-js-plugins-editor";
import classNames from "classnames";
import styles from "./TaskEditor.module.scss";
import { getWordCount } from "../../../../../utils/counts";
import { toMysqlFormat } from "../../../../../utils/date";
import Counter from "../../_components/Counter";
import {
  bannedWordsHighlightPlugin,
  keywordsHighlightPlugin,
} from "./_plugins/WordHighlight";
import {
  HoverButton,
  createInlineCommentsPlugin,
} from "../../_plugins/InlineComments";
import { writingAidPlugin } from "../../_plugins/WritingAid";
import { keywordCommentsPlugin } from "../../_plugins/KeywordCommentsDecorator";
import debounce from "lodash/debounce";
import linkPlugin from "../../_plugins/LinkEditor/LinkDecorator";
import ColorPickerDecorator from "../../_plugins/ColorPicker/ColorPickerDecorator";
import Toolbar from "../../../../DraftJS/Toolbar";

import {
  addInlineStyle,
  removeInlineStyle,
  handleReadOnly,
  replaceText,
} from "../../editorUtils";
import {
  getLSItemV2,
  setLSItemV2,
  removeLSItemV2,
} from "../../../../../utils/localStorage";
import { glossaryWordsHighlightPlugin } from "./_plugins/WordHighlight/WordHighlight";
import { colorInlineStyles } from "../../_plugins/ColorPicker/ColorPickerDropdown";
import { createTablePlugin } from "../../_plugins/draft-js-table-plugin";
import isEqual from "lodash/isEqual";

/**
 * Displays an editable text area
 */
class TaskEditor extends Component {
  constructor(props) {
    super(props);

    this.debounceCheck = debounce(this.check, 1000);
    this.plugins = [
      createTablePlugin({
        onToggleReadOnly: this.onToggleReadOnly,
        atomicUpdate: this.atomicUpdate,
        notEditable: this.props.isWithClient || this.props.isFinalStage,
      }),
    ];
    const compositeDecorators = new CompositeDecorator(this.decorators);
    const editorState = !props.rawContent
      ? EditorState.createEmpty(compositeDecorators)
      : EditorState.createWithContent(
          convertFromRaw(props.rawContent),
          compositeDecorators
        );

    // we want to save the blank rawContent in our state so that we know when it has changed from empty
    const rawContent =
      JSON.stringify(props.rawContent) ||
      JSON.stringify(convertToRaw(editorState.getCurrentContent()));

    this.state = {
      grammarCheck: false,
      editorState,
      savedRawContent: rawContent,
      wordCount: 0,
      isTyping: false,
      readOnly: false,
    };
    this.cachedDecorators = compositeDecorators;
    this.lastPropCacheKey = null;

    this.debounceCheck = debounce(this.check, 1000);
    this.debounceSaveLocalStorage = debounce(this.saveToLocalStorage, 500);
    this.debounceUpdateWordCount = debounce(this.updateWordCount, 300);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !isEqual(this.state.editorState, nextState.editorState) ||
      this.state.readOnly !== nextState.readOnly ||
      this.props.isEditable !== nextProps.isEditable ||
      this.props.rawContent !== nextProps.rawContent ||
      this.props.languageCode !== nextProps.languageCode
    );
  }

  onToggleReadOnly = (readOnly) => {
    this.setState({ readOnly });
  };

  atomicUpdate = (editorState) => {
    this.updateEditorState(editorState, true);
  };

  discardChanges = () => {
    const compositeDecorators = this.getDecorators();
    const stateWithContent = EditorState.createWithContent(
      convertFromRaw(this.props.rawContent),
      compositeDecorators
    );
    this.setState({
      editorState: !this.props.rawContent
        ? EditorState.createEmpty(compositeDecorators)
        : stateWithContent,
    });
  };

  componentDidMount() {
    if (this.props.allowGrammarCheck) {
      this.checkGrammar();
    }
    this.updateWordCount();
    this.wrapperRef.addEventListener("drop", this.handleDrop, true);
  }

  componentWillUnmount() {
    this.wrapperRef.removeEventListener("drop", this.handleDrop, true);
    this.debounceCheck.cancel();
    this.debounceSaveLocalStorage.cancel();
    this.debounceUpdateWordCount.cancel();
  }

  getDecorators() {
    const propKeys = [
      this.props.bannedWords,
      this.props.commentGroups,
      this.props.dualDecorator,
      this.props.keywords,
      this.props.qualityCheck,
      this.props.grammarChecks,
      this.props.glossaryWords,
    ];

    const propCacheKey = JSON.stringify(propKeys);
    if (propCacheKey !== this.lastPropCacheKey) {
      this.lastPropCacheKey = propCacheKey;
      this.cachedDecorators = new CompositeDecorator(this.decorators);
    }
    return this.cachedDecorators;
  }

  get wordCount() {
    return this.state.wordCount;
  }

  get taskState() {
    const { taskFieldId } = this.props;
    const content = this.content;
    const rawContent = this.rawContent;
    const { savedRawContent } = this.state;

    const hasChanged = rawContent !== savedRawContent;

    return { content, rawContent, hasChanged, taskFieldId };
  }

  /**
   * @param {EditorState} editorState
   * @returns {string} JSON.stringified raw content
   */
  get rawContent() {
    const content = this.state.editorState.getCurrentContent();
    return JSON.stringify(convertToRaw(content));
  }

  /**
   * @type {string}
   */
  get content() {
    // html content
    if (this.props.isHTML) {
      const content = this.state.editorState.getCurrentContent();

      const options = {
        inlineStyles: colorInlineStyles,
        blockRenderers: {
          atomic: (block) => {
            const entityKey = block.getEntityAt(0);
            if (!entityKey) return null;

            const entity = content.getEntity(entityKey);
            if (entity.getType() === "draft-js-table-plugin") {
              const { columns, rows } = entity.getData();

              let tableHTML = "<table>";

              // Add header row with column titles
              tableHTML += "<thead><tr>";
              columns.forEach((col) => {
                tableHTML += `<th>${col.value}</th>`;
              });
              tableHTML += "</tr></thead>";

              // Add data rows
              tableHTML += "<tbody>";
              rows.forEach((row) => {
                tableHTML += "<tr>";
                row.value.forEach((cell) => {
                  tableHTML += `<td>${cell.value}</td>`;
                });
                tableHTML += "</tr>";
              });
              tableHTML += "</tbody></table>";

              return tableHTML;
            }
            return null;
          },
        },
      };

      const html = stateToHTML(content, options);

      // if we replace all p tags and br tags and there is no content, do not send "<p></p><br/>" to the server, but instead an empty string
      if (html.replace(/(<\/?p[^>]*>|<br[^>]*>)/g, "").trim().length === 0) {
        return "";
      }

      return html;
    }

    // plaintext
    return this.state.editorState.getCurrentContent().getPlainText();
  }

  /**
   * @type {Object[]} array of decorator objects
   */
  get decorators() {
    const {
      bannedWords,
      commentGroups,
      dualDecorator,
      keywords,
      openCommentBox,
      qualityCheck,
      openSuggestionBox,
      grammarChecks,
      allowGrammarCheck,
      glossaryWords,
      languageCode,
    } = this.props;

    const decorators = [
      createInlineCommentsPlugin(commentGroups, openCommentBox),
      linkPlugin,
      bannedWordsHighlightPlugin(bannedWords, languageCode),
    ];

    if (dualDecorator) {
      decorators.unshift(
        keywordCommentsPlugin(commentGroups, openCommentBox, keywords)
      );
    }

    // only if the task is quality checked we show keywords
    if (qualityCheck) {
      decorators.push(keywordsHighlightPlugin(keywords));
    }

    if (allowGrammarCheck) {
      decorators.push(
        writingAidPlugin(grammarChecks?.corrections, openSuggestionBox)
      );
    }

    decorators.push(glossaryWordsHighlightPlugin(glossaryWords));
    decorators.push(ColorPickerDecorator);
    return decorators;
  }

  /**
   * @type {string}
   */
  get format() {
    return this.props.isHTML ? "html" : "text";
  }

  /**
   * Calculate the word count and store in the state
   */
  updateWordCount() {
    const wordCount = getWordCount(this.content, this.format);

    if (wordCount !== this.state.wordCount) {
      this.setState({ wordCount }, this.props.onWordCountUpdate);
    }
  }

  /**
   * @returns an array of keys that reference to this tasks local storage item
   */
  get localStorageKeys() {
    const { deliverableId, stageId, personId, taskFieldId } = this.props;
    return [personId, `${deliverableId}.${stageId}`, taskFieldId];
  }

  /**
   * Saves this tasks content to a local storage item
   */
  saveToLocalStorage = () => {
    const { content, rawContent } = this.taskState;
    const { stageId } = this.props;

    setLSItemV2(this.localStorageKeys, {
      content,
      createDate: toMysqlFormat(new Date()),
      rawContent,
      stageId,
    });
  };

  /**
   * Saves a task immediately without debouncing
   */
  saveImmediately = () => {
    const { deliverableId, personId, saveSingleTask, stageId, taskFieldId } =
      this.props;
    const { content, rawContent } = this.taskState;

    saveSingleTask({
      content,
      deliverableId,
      personId,
      rawContent,
      stageId,
      taskFieldId,
    });
  };

  mapCorrections = (editorState) => {
    const raw = convertToRaw(editorState.getCurrentContent());
    raw.blocks.forEach((block) => {
      block.inlineStyleRanges = block.inlineStyleRanges.filter(
        (inlineStyle) => {
          return inlineStyle.style.substring(0, 11) !== "CORRECTION-";
        }
      );

      const { corrections = [] } = this.props.grammarChecks || {};
      if (corrections[block.key]) {
        corrections[block.key].forEach((cor) => {
          if (!cor.ud) {
            block.inlineStyleRanges.push({
              offset: cor.offset,
              length: cor.length,
              style: `CORRECTION-${cor.offset}`,
            });
          }
        });
      }
    });

    const newSelection = this.state.editorState.getSelection();
    const newContentState = EditorState.createWithContent(convertFromRaw(raw));

    const newEditorStateWithSelection = EditorState.forceSelection(
      newContentState,
      newSelection
    );

    this.updateEditorState(newEditorStateWithSelection);
  };

  fetchCorrections = async () => {
    const {
      processTransitionGrammarCheck,
      deliverableId,
      taskFieldId,
      languageCode,
    } = this.props;
    await processTransitionGrammarCheck({
      deliverableIds: [deliverableId],
      rawContent: JSON.stringify(
        convertToRaw(this.state.editorState.getCurrentContent())
      ),
      taskFieldId,
      languageCode,
    });
  };

  checkGrammar = async () => {
    const correctionRawContent = this.props.grammarChecks?.rawContent;
    const latestRawContent = JSON.stringify(this.props.rawContent);

    if (latestRawContent !== correctionRawContent) {
      await this.fetchCorrections();
    }

    this.mapCorrections(this.state.editorState);
  };

  check = async (editorState) => {
    await this.fetchCorrections();
    this.setState({ isTyping: false });

    const currentContent = editorState.getCurrentContent();
    const previousContent = this.state.editorState.getCurrentContent();

    if (!this.state.isTyping && currentContent !== previousContent) {
      const lastChangeType = editorState.getLastChangeType();

      if (lastChangeType) {
        const selection = editorState.getSelection();
        const startOffset = selection.getStartOffset();
        const endOffset = selection.getEndOffset();

        // Extract only the changed text
        const updatedText = currentContent
          .getBlockForKey(selection.getStartKey())
          .getText()
          .slice(startOffset, endOffset);

        this.mapCorrections(editorState, updatedText, startOffset, endOffset);
      }
    }
  };

  /**
   * Update state when draft.js editor state changes
   */
  updateEditorState = (editorState, atomic = false) => {
    const currentContent = editorState.getCurrentContent();
    const previousContent = this.state.editorState.getCurrentContent();

    if (currentContent === previousContent && !atomic) return;

    this.setState({ editorState, isTyping: true }, () => {
      this.debounceUpdateWordCount();
      this.debounceSaveLocalStorage();
      this.props.allowGrammarCheck && this.debounceCheck(editorState);
    });

    this.props.debounceSaveAll();
  };

  applySuggestion = (textContent, text, start) => {
    const { editorState } = this.state;

    let selectionState = editorState.getSelection();

    const anchorOffset = start;
    const focusOffset = start + text.length;

    selectionState = selectionState.merge({
      anchorOffset,
      focusOffset,
      isBackward: false,
      hasFocus: false,
    });

    this.updateEditorState(
      replaceText(this.state.editorState, textContent, selectionState)
    );
  };

  addToDictionary = async (textContent) => {
    await this.props.addToDictionary(textContent);
    await this.checkGrammar();
  };

  /**
   * @param {string} styleName the style name to add
   * @param {Object} [selection] draft js selection to apply the style to
   * @param {string} [changeType] the change type
   */
  addStyle = (styleName, selection, changeType) => {
    this.updateEditorState(
      addInlineStyle(this.state.editorState, styleName, selection, changeType)
    );
  };

  /**
   * @param {string} styleName the style name to add
   * @param {Object} [selection] draft js selection to apply the style to
   * @param {string} [changeType] the change type
   */
  removeStyle = (styleName, selection, changeType = "remove-inline-style") => {
    this.updateEditorState(
      removeInlineStyle(
        this.state.editorState,
        styleName,
        selection,
        changeType
      )
    );
  };

  onSave = (savedRawContent) => {
    const localStorageTFC = getLSItemV2(this.localStorageKeys) || {};
    this.setState(
      {
        savedRawContent,
      },
      () => {
        if (localStorageTFC.rawContent === savedRawContent) {
          // after we've saved clean up our local storage
          removeLSItemV2(this.localStorageKeys);
        }
      }
    );
  };

  /**
   * Render the CommentGroup and comment HoverButton components if necessary
   */
  renderCommentHoverButton() {
    const { isCommentable } = this.props;
    const { editorState } = this.state;

    // Hover new comment group button above selected text
    const selection = editorState.getSelection();

    if (
      isCommentable &&
      typeof window !== "undefined" &&
      selection.getHasFocus()
    ) {
      const selectionRect = getVisibleSelectionRect(window);

      if (selectionRect && selectionRect.width > 1) {
        selectionRect.top += window.pageYOffset;
        selectionRect.bottom += window.pageYOffset;

        return (
          <HoverButton
            openCommentBox={this.props.openCommentBox}
            selectionRect={selectionRect}
          />
        );
      }
    }
  }

  /**
   * Prevents drag/drop events if the task is not editable
   *
   * @param {Event} event
   */
  handleDrop = (event) => {
    if (!this.props.isEditable) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  /**
   * @param {string} text pasted text
   * @param {string} html pasted html
   * @param {editorState} EditorState Editor state
   */
  handlePastedText = (text, _html, editorState) => {
    // for HTML fields allow everything to be pasted
    if (this.props.isHTML) return "not-handled";

    // for plaintext strip all formatting/styling
    const pastedContent = ContentState.createFromText(text);
    const newContentState = Modifier.replaceWithFragment(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      pastedContent.blockMap
    );
    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      "insert-fragment"
    );

    this.updateEditorState(newEditorState);

    return "handled";
  };

  /**
   * This function is responsible for making keyboard shortcuts work for
   * formatting: CMD+B = BOLD, CMD+I = ITALIC, etc.
   */
  handleKeyCommand = (command, newEditorState) => {
    // let draftjs process plaintext commands (but not rich text commands)
    if (!this.props.isHTML) return "not-handled";

    const newState = RichUtils.handleKeyCommand(newEditorState, command);

    if (newState) {
      this.updateEditorState(newState);
      return "handled";
    }

    return "not-handled";
  };

  render() {
    const { languageCode } = this.props;
    const decorators = this.decorators;
    const compositeDecorators = new CompositeDecorator(decorators);
    const withDecorators = EditorState.set(this.state.editorState, {
      decorator: compositeDecorators,
    });

    const {
      isEditable,
      isFinalStage,
      isHTML,
      minWords,
      maxWords,
      minCharacters,
      maxCharacters,
      qualityCheck,
    } = this.props;

    return (
      <div
        ref={(node) => {
          this.wrapperRef = node;
        }}
        className={classNames({
          [styles.isFinalStage]: isFinalStage,
          [styles.taskEditor]: true,
          [styles.readOnly]: !isEditable,
        })}
      >
        <div className={styles.editorContainer}>
          {isHTML && (
            <Toolbar
              editorState={withDecorators}
              disabled={!isEditable}
              setEditorState={this.updateEditorState}
              showLinkButton
            />
          )}
          <Editor
            decorators={decorators}
            editorState={withDecorators}
            handlePastedText={this.handlePastedText}
            handleKeyCommand={this.handleKeyCommand}
            keyBindingFn={!isEditable ? handleReadOnly : null}
            onChange={this.updateEditorState}
            plugins={this.plugins}
            spellCheck={isEditable}
            textAlignment={this.props.textAlignment}
            textDirectionality={this.props.textDirectionality}
            // For e2e testing (https://github.com/cypress-io/cypress/issues/596)
            webDriverTestID={`taskEditor-${this.props.taskFieldId}`}
            readOnly={this.state.readOnly}
          />
        </div>

        <Counter
          editorState={this.state.editorState}
          maxCharacters={maxCharacters}
          maxWords={maxWords}
          minCharacters={minCharacters}
          minWords={minWords}
          qualityCheck={qualityCheck}
          wordCount={this.state.wordCount}
        />

        {this.renderCommentHoverButton()}
      </div>
    );
  }
}

TaskEditor.propTypes = {
  bannedWords: PropTypes.array,
  commentGroups: PropTypes.object.isRequired,
  debounceSaveAll: PropTypes.func.isRequired,
  deliverableId: PropTypes.number.isRequired,
  isCommentable: PropTypes.bool.isRequired,
  isEditable: PropTypes.bool.isRequired,
  isFinalStage: PropTypes.bool,
  keywords: PropTypes.array,
  maxCharacters: PropTypes.number,
  maxWords: PropTypes.number,
  minCharacters: PropTypes.number,
  minWords: PropTypes.number,
  onWordCountUpdate: PropTypes.func.isRequired,
  openCommentBox: PropTypes.func.isRequired,
  personId: PropTypes.number.isRequired,
  qualityCheck: PropTypes.bool,
  rawContent: PropTypes.object,
  saveSingleTask: PropTypes.func.isRequired,
  stageId: PropTypes.number.isRequired,
  taskFieldId: PropTypes.number.isRequired,
  languageCode: PropTypes.string.isRequired,
  allowGrammarCheck: PropTypes.bool,
  processTransitionGrammarCheck: PropTypes.func,
  grammarChecks: PropTypes.shape({
    corrections: PropTypes.object,
    rawContent: PropTypes.string,
  }),
  openSuggestionBox: PropTypes.func,
  addToDictionary: PropTypes.func,
  glossaryWords: PropTypes.array,
  textAlignment: PropTypes.string,
  textDirectionality: PropTypes.string,
  isHTML: PropTypes.bool,
  dualDecorator: PropTypes.bool,
};

export default TaskEditor;
