import { gql, useQuery } from "@apollo/client";
import classNames from "classnames";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DropdownV2 from "../../../../components/DropdownV2";
import { exportFormatByProjectIdQuery } from "../../../../modules/exportFormats";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../../modules/messagesV2";
import { getBounds } from "../../../../utils/dom";
import { isLoggedInUserAdmin } from "../../../../utils/entitySelector";
import FeatureToggle from "../../../FeatureToggle";
import Icon from "../../../Icon";
import styles from "./DownloadCSVOrExcel.module.scss";

export const GET_EXPORT_FORMAT = gql`
  query getExportFormat ($projectId: String) {
    ${exportFormatByProjectIdQuery}
  }
`;

type OptionsType = {
  stageId: number;
  batchId: number;
  languageCode: string;
  exportFormat: object;
};

type PropTypes = {
  batchId: number;
  customDownloadButtons: {
    lvCreation: boolean;
    lvLocalisation: boolean;
  };
  useCustomIcon?: JSX.Element | null;
  download: (
    options: OptionsType,
    format: string,
    clientFormat?: string
  ) => void;
  stageId: number;
  projectId: number;
  languageCode: string;
  downloads?: object;
  disabled?: boolean;
  isMergedOutputs?: boolean;
};

function getBatchLangs(downloads) {
  try {
    const stageId = Number(Object.keys(downloads)[0]);
    const batchLangs = Object.keys(downloads[stageId]).join(";");
    return batchLangs;
  } catch (_) {
    return null;
  }
}

const DownloadCSVOrExcel = ({
  batchId,
  downloads = {},
  customDownloadButtons: { lvCreation, lvLocalisation },
  useCustomIcon,
  download,
  stageId,
  projectId,
  languageCode,
  isMergedOutputs,
}: PropTypes) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [shouldOpenUpwards, setShouldOpenUpwards] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const isAdmin = useSelector(isLoggedInUserAdmin);

  // merged outputs is only active when batches are selected
  const isDisabled = useMemo(() => {
    const anyBatchesSelected = Object.keys(downloads).length > 0;

    const mergedOutputsDisabled = isMergedOutputs && !anyBatchesSelected;
    const downloadCsvDisabled = !isMergedOutputs && anyBatchesSelected;

    return mergedOutputsDisabled || downloadCsvDisabled;
  }, [downloads, isMergedOutputs]);

  const mergedOutputCount = useMemo(() => {
    if (!isMergedOutputs) {
      return 0;
    }

    const stageIds = Object.keys(downloads);

    if (stageIds.length === 0) {
      return 0;
    }

    return stageIds.reduce((acc, stageId) => {
      const batchLangs = Object.keys(downloads[stageId]);
      return acc + batchLangs.length;
    }, 0);
  }, [downloads, isMergedOutputs]);

  const queryData = useQuery(GET_EXPORT_FORMAT, {
    variables: { projectId },
    fetchPolicy: "no-cache",
  });

  const showDocExport = queryData?.data?.exportFormat?.docExport;

  const requestDownload = async (
    e: React.ChangeEvent<HTMLInputElement>,
    format: string,
    clientFormat: string = ""
  ) => {
    e.preventDefault();

    const { exportFormat } = queryData!.data;

    const batchLangs = getBatchLangs(downloads);

    const options = {
      batchLangs,
      stageId,
      batchId,
      languageCode,
      exportFormat,
    };

    return download(options, format, clientFormat);
  };

  const downloadCSV = (e) => {
    requestDownload(e, "csv");
  };

  const downloadExcel = (e) => {
    requestDownload(e, "excel");
  };

  const downloadLVEurope = (e) => {
    requestDownload(e, "excel", "lv-europe");
  };

  const downloadEnNl = (e) => {
    requestDownload(e, "excel", "en-nl");
  };

  const downloadProductCare = (e) => {
    requestDownload(e, "excel", "product-care");
  };

  const downloadLVAsia = (e) => {
    requestDownload(e, "excel", "lv-asia");
  };

  const downloadLVUSCreation = (e) => {
    requestDownload(e, "excel", "lv-us-creation");
  };

  const downloadWatchesExtract = (e) => {
    requestDownload(e, "excel", "watches-extract");
  };

  const downloadProductNames = (e) => {
    requestDownload(e, "excel", "lv-product-names");
  };

  const downloadGoogleDoc = async (e) => {
    try {
      await requestDownload(e, "google-doc");
      dispatch(
        showSuccessMessage(
          "Your export has been requested. You will receive an email when it's ready."
        )
      );
    } catch (err: any) {
      dispatch(showErrorMessage(err.message));
    }
  };

  const svgStyles = classNames({
    [styles.svg]: true,
    [styles.toggled]: open,
    [styles.disabled]: isDisabled,
  });

  const customIcon = useCustomIcon && (
    <div className={svgStyles}>
      <Icon name="Download" size="lg" />
    </div>
  );

  const onToggle = useCallback((toggled) => {
    setOpen(toggled);

    if (toggled) {
      setShouldOpenUpwards(
        getBounds(ref.current).top - window.innerHeight / 2 > 100
      );
    }
  }, []);

  const mainDownloadStyles = classNames({
    [styles.download]: true,
    [styles.disabled]: isDisabled,
  });

  return (
    <div ref={ref} className={mainDownloadStyles}>
      <DropdownV2
        customIcon={customIcon}
        onToggle={onToggle}
        disabled={isDisabled}
        shouldOpenUpwards={shouldOpenUpwards}
        title={
          isMergedOutputs
            ? `Download selected (${mergedOutputCount})`
            : "Download"
        }
        disabledTooltip={
          isMergedOutputs && isDisabled
            ? "Select multiple batches first"
            : "For merged outputs, use the dropdown above"
        }
      >
        <option onClick={downloadCSV}>CSV</option>
        <option onClick={downloadExcel}>Excel</option>

        {showDocExport && !isMergedOutputs && (
          <FeatureToggle toggle="docExport">
            {isAdmin && <option onClick={downloadGoogleDoc}>Google Doc</option>}
          </FeatureToggle>
        )}

        {lvLocalisation && [
          <option key="lv-europe" onClick={downloadLVEurope}>
            LV Extract - All Markets
          </option>,
          <option key="lv-asia" onClick={downloadProductCare}>
            LV Product Care - Europe
          </option>,
          <option key="lv-asia" onClick={downloadLVAsia}>
            LV Extract - Asia
          </option>,
          <option key="en-nl" onClick={downloadEnNl}>
            LV Extract - English/NL
          </option>,
          <option key="watches-extract" onClick={downloadWatchesExtract}>
            LV Watches Extract
          </option>,
          <option key="lv-product-names" onClick={downloadProductNames}>
            LV Product Names
          </option>,
        ]}

        {lvCreation && (
          <option key="lv-us-creation" onClick={downloadLVUSCreation}>
            LV Extract
          </option>
        )}
      </DropdownV2>
    </div>
  );
};

export default DownloadCSVOrExcel;
